<template>
	<div class="details">
		<!-- <Selectshop
		 :proData="transmitData"
		  /> -->
		<div class="conten_body">  
			<div class="conten_left">
				<div class="result-item">
					<a  target="_blank">
	                <h3>等保咨询服务 -安全管家 </h3>
		            <div class="intro">本文档介绍阿里云咨询服务本文档介绍阿里云咨询服务本文档介绍阿里云咨询服务本文档介绍阿里云咨询服务本文档介绍阿里云咨询服务本文档介绍阿里云咨询服务</div>
		            </a>
		        </div>
		        <div class="result-item">
					<a  target="_blank">
	                <h3>等保咨询服务 -安全管家 </h3>
		            <div class="intro">本文档介绍阿本文档介绍阿里云咨询服务本文档介绍阿里云咨询服务本文档介绍阿里云咨询服务本文档介绍阿里云咨询服务里云咨询本文档介绍阿里云咨询服务服务</div>
		            </a>
		        </div>
		        <div class="result-item">
					<a  target="_blank">
	                <h3>等保咨询服务 -安全管家 </h3>
		            <div class="intro">本文档介绍阿里本文档本文档介绍阿里云咨询服务介绍阿里云咨询服务本文档介绍阿里云咨询服务本文档介绍阿里云咨询服务本文档介绍阿里云咨询服务云咨询服务</div>
		            </a>
		        </div>
		         <el-pagination
	              class="pagestyle"
			      @size-change="handleSizeChange"
			      @current-change="handleCurrentChange"
			      :current-page="currentPage4"
			      :page-sizes="[10, 30, 50, 100]"
			      :page-size="10"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="4">
			    </el-pagination>
                                    
			</div>
			<!-- right -->
			<Hotgoods/>

			
			
		</div>
	</div>
</template>

<script type="text/javascript">
import Hotgoods from "@/components/hotgoods.vue"
// import Selectshop from "@/components/Selectshop.vue"

export default{
components:{
      Hotgoods,
      // Selectshop
	},
data(){
	return{
		transmitData:{
			title:"ICP/EDI许可证第三方风险评估",
			content:"",
			price:'0',
			url:require('../../assets/images/icpedi/2.png')
		}
	}
},
mounted(){

},
methods:{
	handleSizeChange(){

	},
	handleCurrentChange(){
		
	}



}

};
</script>

<style type="text/css" lang="less" scoped>
.details{
	width:1200px;
	margin:0 auto;

//**/
.conten_body{
	margin-top:10px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}

.con_list{
	// padding:0 35px;
	overflow:hidden;
}
.result-item {
    margin-bottom: 32px;
    padding:0 10px;
}
.result-item h3 {
    font-size: 16px;
    height: 24px;
    line-height: 24px;
    color:#333;
    text-decoration: none;
}
.result-item .intro {
    font-size: 14px;
    margin-top: 8px;
    color: #73777A;
    line-height: 24px;
}
.pagestyle{
	// float:right;
	margin:20px;

}



}


</style>








